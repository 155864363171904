import styles from './Dashboard.module.scss'

import NaturalGasProductionByCompany from './components/NaturalGasProductionByCompany'
import NaturalGasProductionByDeposit from './components/NaturalGasProductionByDeposit'

export default function Dashboard() {
	return (
		<div className={styles.dashboard_wrapper}>
			<NaturalGasProductionByDeposit />
			<NaturalGasProductionByCompany />
		</div>
	)
}
