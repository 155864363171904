
import styles from './Deposits.module.scss';
import Swal from "sweetalert2";
import axios from '../../api/axios';
import Owners from './components/Owners';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Checkbox, Input, Loading, Modal, Pagination, Select, Table, Tooltip } from "react-daisyui";
import { Deposit } from './interface';


export default function Deposits() {
	const [deposits, setDeposits] = useState<Array<Deposit>>([]);
	const [deposit, setDeposit] = useState<Deposit | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [saveLoading, setSaveLoading] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');
	const [total, setTotal] = useState(0)
	const [limit, setLimit] = useState(20)
	const [page, setPage] = useState(1)

	const [name, setName] = useState<string>('');
	const [dry, setDry] = useState<boolean>(false);
	const [associated, setAssociated] = useState<boolean>(false);

	const detailModal = useRef<HTMLDialogElement>(null);
	const ownersModal = useRef<HTMLDialogElement>(null);

	const renderPageNumbers = () => {
		const pageNumbers = [];
		const maxPageButtons = 5;
		const totalPages = Math.ceil(total / limit);
		const maxPage = Math.min(totalPages, maxPageButtons);
		const middlePage = Math.ceil(maxPage / 2);
		const startPage = Math.max(1, page - middlePage);
		const endPage = Math.min(totalPages, startPage + maxPage - 1);

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(
				<Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
					{i}
				</Button>
			);
		}

		return pageNumbers;
	}

	const downloadDeposits = async () => {
		setLoading(true);

		axios.get("/api/deposits?offset=" + (page - 1) * limit + "&limit=" + limit + (search ? "&filters[search]=" + search : '')).then(response => {
			setDeposits(response.data.data);
			setTotal(response.data.total);
		}).finally(() => {
			setLoading(false);
		});
	}

	useEffect(() => {
		downloadDeposits();
	}, [page, limit, search])

	const depositOwners = (deposit: Deposit) => {
		setDeposit(deposit);
		ownersModal.current?.showModal();
	};

	const editDeposit = (deposit: Deposit) => {
		setDeposit(deposit);
		detailModal.current?.showModal();

		setName(deposit.name);
		setDry(deposit.dry);
		setAssociated(deposit.associated);
	};

	const save = () => {
		setSaveLoading(true);

		axios(`/api/deposits${deposit && deposit.id ? `/${deposit.id}` : ''}`, {
			method: deposit && deposit.id ? 'PUT' : 'POST',
			data: {
				...deposit,
				name,
				dry,
				associated
			}
		}).then(() => {
			Swal.fire({
				heightAuto: false,
				icon: 'success',
				title: '¡Listo!',
				text: 'El depósito ha sido guardado correctamente.'
			});
		}).finally(() => {
			setSaveLoading(false);

			detailModal.current?.close();

			setDeposit(null);
			downloadDeposits();
		});
	}

	const debounced = useDebouncedCallback((value) => {
		setSearch(value);
	}, 500);

	return (
		<>
			<div className={styles.deposits_wrapper}>
				<div className={styles.actions}>
					<Input className='w-full' placeholder="Buscar" size="sm" onChange={(e) => debounced(e.target.value)} />
				</div>
				<div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
					<div style={{overflow: 'auto'}}>
						{loading ? <div className={styles.loading}><Loading color="primary" /></div> :
							<Table zebra pinRows>
								<Table.Head className="th_acciones">
									<span>Nombre</span>
									<span>Propietarios</span>
									<span>Seco</span>
									<span>Asociado</span>
									<span>Acciones</span>
								</Table.Head>
								<Table.Body>
									{deposits.map((deposit, index) => (
										<Table.Row hover key={index}>
											<span>{deposit.name}</span>
											<span>{deposit.area_yacimiento_propietarios && deposit.area_yacimiento_propietarios.map((owner, index) => {
												return <div>
													<span key={index}>{owner.empresa} ({owner.porcentaje}%)</span>
													<br></br>
												</div>
											})}</span>
											<span>{deposit.dry ? <Badge color="accent">Si</Badge> : <Badge color="error">No</Badge>}</span>
											<span>{deposit.associated ? <Badge color="accent">Si</Badge> : <Badge color="error">No</Badge>}</span>
											<span className="flex gap-2">
												<Tooltip message="Propietarios">
													<Button size="sm" shape="square" color="primary" onClick={() => depositOwners(deposit)}>
													<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"><path d="M40-272q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v32q0 33-23.5 56.5T600-160H120q-33 0-56.5-23.5T40-240v-32Zm800 112H738q11-18 16.5-38.5T760-240v-40q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v40q0 33-23.5 56.5T840-160ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>
													</Button>
												</Tooltip>
												<Tooltip message="Editar">
													<Button size="sm" shape="square" color="secondary" onClick={() => editDeposit(deposit)}>
														<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"><path d="M200-200h57l391-391-57-57-391 391v57Zm-40 80q-17 0-28.5-11.5T120-160v-97q0-16 6-30.5t17-25.5l505-504q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L313-143q-11 11-25.5 17t-30.5 6h-97Zm600-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
													</Button>
												</Tooltip>
											</span>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						}
					</div>
				</div>
				<div className={styles.pagination}>
					<div className={styles.cantidad}>
						<small >Cantidad por página:</small>
						<Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={20}>20</option>
						</Select>
					</div>
					<Pagination className="flex justify-center">
						<Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
							Anterior
						</Button>
						{renderPageNumbers()}
						<Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
							Siguiente
						</Button>
					</Pagination>
				</div>
			</div>
			<Modal ref={detailModal} backdrop={true}>
				<form method="dialog">
					<Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }}>
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
					</Button>
				</form>
				<Modal.Header className="font-bold">{name}</Modal.Header>
				<Modal.Body>
					{saveLoading ? <Loading color='primary' /> : <>
						<div className="p-4">
							<div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
								<label>Seco</label>
								<Checkbox checked={dry ?? false} onChange={(e) => setDry(e.target.checked)} />
							</div>
							<br></br>
							<div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
								<label>Asociado</label>
								<Checkbox checked={associated ?? false} onChange={(e) => setAssociated(e.target.checked)} />
							</div>
						</div>
						<div className="p-4">
							<Button color="primary" fullWidth onClick={save}>Guardar</Button>
						</div>
					</>}
				</Modal.Body>
			</Modal>
			<Owners ref={ownersModal} deposit={deposit} />
		</>
	)
}